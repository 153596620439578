.background_blue {
    background-color: #eff7ff;
}
.myClass_wrapper {
    width: 100%;
    display: flex;
    gap: 2rem;
    padding: 2rem;
}
.myClass_col {
    width: 50%;
}
.myClass_box {
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    padding: 1.5rem;
}

.myClass_col {
    display: flex;
    gap: 2rem;
    flex-direction: column;
}

.page_content_inner {
    padding-bottom: 0;
    max-height: inherit;
}

.info_input_layout {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.input_title_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}

.input_title_row span {
    font-size: 18px;
    font-family: noto_B;
}

.common_input_box_layout {
    background-color: #eff7ff;
    border-radius: 6px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-family: noto_B;
    height: 65px;
    padding: 0 1.5rem;
}

.book_info {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
}

.book_info_wrapper {
    display: flex;
    align-items: flex-start;
    gap: 2rem;
}
.book_img {
    background: #aaa;
    min-width: 240px;
    height: 330px;
    border-radius: 10px;
}

.myClass_unit_list {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 1.5rem;
}
.myClass_unit_list li {
    height: 100px;
    width: calc(50% - 1.5rem);
    border: 1px solid #c5d7ff;
    background-color: #eff7ff;
    border-radius: 7px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0 1.5rem;
    gap: 20px;
}

div.book_img {
    max-height: inherit;
}

.myClass_unit_list li h1 {
    font-size: 20px;
    font-family: "noto_B";
    color: #a4c0ff;
}

.progress_bar {
    width: 70%;
    background: #fff;
    border-radius: 100px;
    height: 25px;
    position: relative;
}
.progress_bar span {
    background: linear-gradient(90deg, #a4c8ed 1.81%, #77bbff 100%), #769efc;
    border-radius: 7.14rem;
    height: 100%;
    position: absolute;
    width: 60%;
    left: 0;
    top: 0;
    margin-top: 0;
}

.event_slider_container {
    overflow: hidden;
    height: 300px;
    width: 70%;
}

.event_slider_row {
    display: flex;
    flex-direction: row;
    gap: 20px;
}
.event_product {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.event_product img {
    width: 70%;
}

.event_slider_container .swiper-slide {
    width: 100%;
    height: 100px;
    border: 1px solid #c5d7ff;
    background-color: #eff7ff;
    border-radius: 7px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0 1.5rem;
    gap: 20px;
    justify-content: space-between;
}
.event_slider_container .swiper-slide > div {
    display: flex;
    align-items: center;
    gap: 10px;
}

.event_slider_container .swiper-slide > div > img {
}
.event_slider_container .swiper-slide > div > h1 {
    color: #2e86df;
    font-size: 18px;
}
.event_slider_container .swiper-slide > h2 {
    font-size: 16px;
    color: #2e86df;
}
.event_slider_container .swiper-slide > h3 {
    font-size: 16px;
    color: #2e86df;
}

.input_title_row h2 {
    font-family: noto_R;
    font-size: 16px;
}
.input_title_row h2 span {
    font-family: noto_B;
    font-size: 16px;
}
