.wrap {
  width: 100%;
  height: 100vh;
  background-color: #2e86df;
  overflow: hidden;
  font-size: 0;
  padding: 0 40px;
}

div.alert {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
}

div.alert div.alert_inner {
  width: auto;
  height: 8.57rem;
  background: rgba(255, 122, 0, 0.8);
  border-radius: 7.14rem;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 0 3rem;
}

.read_alert > .alert_inner {
  background: rgba(212, 164, 106, 0.8) !important;
}

div.alert div.alert_inner img {
  margin-right: 2rem;
}

div.alert div.alert_inner h1 {
  text-shadow: 0rem 0.14rem 0.14rem rgba(0, 0, 0, 0.26);
  font-size: 2.43rem;
  color: #fff;
}

.display_none {
  display: none !important;
}

div.page_header {
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 0;
}

div.page_header div.user_left {
  display: inline-block;
  font-size: 0;
  vertical-align: middle;
  min-width: 25.36rem;
}

div.page_header div.user_left p.user_photo {
  width: 7.86rem;
  height: 7.86rem;
  display: inline-block;
  vertical-align: middle;
  background: #fff;
  border: 0.36rem solid #ff8515;
  box-shadow: 0rem 0.29rem 0.29rem rgba(0, 0, 0, 0.25);
  border-radius: 1.07rem;
  margin-right: 1.25rem;
  font-size: 0;
  background: url(../images/common/avatar_3.png) no-repeat;
  background-size: 100%;
  background-position: center top;
  background-color: #fff;
  border: 0.36rem solid #ecde06;
}

div.page_header div.user_left div.col {
  display: inline-block;
  vertical-align: middle;
}

div.page_header div.user_left div.col h1.user_name {
  font-size: 1.71rem;
  color: #fff;
  text-shadow: 0rem 0.14rem 0.07rem rgba(0, 0, 0, 0.25);
}

div.mic_permission {
  display: flex;
  flex-direction: row;
}

div.mic_permission p.title {
  text-align: center;
  font-size: 1.71rem;
  color: #fff;
  text-shadow: 0rem 0.14rem 0.07rem rgba(0, 0, 0, 0.25);
}

.mic_permission_switch {
  display: inline-flex;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  flex-shrink: 0;
  z-index: 0;
  vertical-align: middle;
  width: 50px;
  height: 34px;
  padding: 0px;
  margin: 8px;
  margin-right: 20px;
  border: 4px solid transparent;
}

@keyframes blink-border {
  0%,
  100% {
    border-color: #ff3300;
  }
  50% {
    border-color: transparent;
  }
}

.mic_permission_switch.blink-border {
  border: 4px solid #ff3300;
  border-radius: 34px;
  animation: blink-border 1s infinite;
}

.mic_permission_switch .circle {
  width: 22px;
  height: 22px;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  padding: 9px;
  border-radius: 50%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  color: rgb(255, 255, 255);
  transition:
    left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding: 0px;
  margin: 2px;
  transition-duration: 300ms;
}

.mic_permission_switch .circle.on {
  transform: translateX(16px);
  color: rgb(255, 255, 255);
}

.mic_permission_switch .circle input {
  cursor: inherit;
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  margin: 0px;
  padding: 0px;
  z-index: 1;
  left: -100%;
  width: 300%;
}

.mic_permission_switch .circle .thumb {
  box-shadow:
    rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
    rgba(0, 0, 0, 0.14) 0px 1px 1px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  background-color: currentcolor;
  border-radius: 50%;
  box-sizing: border-box;
  width: 22px;
  height: 22px;
}

.mic_permission_switch .track {
  height: 100%;
  width: 100%;
  z-index: -1;
  border-radius: 13px;
  background-color: #e9e9ea;
  opacity: 1;
  transition: background-color 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.mic_permission_switch .circle.on + .track {
  background-color: rgb(101, 196, 102);
  opacity: 1;
  border: 0px;
}

div.page_header div.user_left div.col div.row {
  margin-top: 0.75rem;
}

div.page_header div.user_left div.col div.row p {
  box-shadow: 0rem 0.14rem 0.07rem rgba(0, 0, 0, 0.25);
  width: 7.14rem;
  height: 2.14rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 7.14rem;
  background: #fff;
  color: #2e86df;
  font-size: 1.71rem;
  vertical-align: middle;
  margin-top: 0;
}

div.page_header div.user_left div.col div.row span.dot_number {
  font-size: 1.71rem;
  color: #fff;
  text-shadow: 0rem 0.14rem 0.07rem rgba(0, 0, 0, 0.25);
  vertical-align: middle;
  margin-left: 0.5rem;
  display: inline-flex;
}

div.page_header div.page_name h1 {
  font-size: 4rem;
  text-align: center;
  color: #fff;
  text-shadow: 0rem 0.14rem 0.07rem rgba(0, 0, 0, 0.25);
}

div.page_header div.btn_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

div.page_header div.btn_wrapper ul.btn_list {
  font-size: 0;
  text-align: right;
}

div.page_header div.btn_wrapper ul.btn_list li {
  width: 7.86rem;
  height: 7.86rem;
  display: inline-block;
  vertical-align: middle;
  background: #0f69c3;
  border: 0.36rem solid #fff;
  box-shadow: 0rem 0.29rem 0.29rem rgba(0, 0, 0, 0.25);
  border-radius: 1.07rem;
  font-size: 0;
  cursor: pointer;
}

div.page_header div.btn_wrapper ul.btn_list li:nth-of-type(2) {
  margin: 0 0.8rem;
}

div.page_header div.btn_wrapper ul.btn_list li a {
  display: flex;
  width: 100%;
  height: 100%;
}

div.page_content {
  margin: 0 auto;
  width: 100%;
  position: relative;
}

div.page_content div.page_notice {
  text-align: center;
  padding: 1.5rem 0;
  font-size: 0;
}

div.page_content div.page_notice p {
  display: inline-flex;
  font-size: 1.79rem;
  vertical-align: middle;
  color: #fff;
  border-radius: 0.5rem;
  background: #ff8415;
  width: 2.14rem;
  height: 2.14rem;
  align-items: center;
  justify-content: center;
  padding-top: 0.29rem;
}

.icon_notice {
  display: inline-flex;
  font-size: 1.79rem;
  vertical-align: middle;
  color: #fff;
  border-radius: 0.5rem;
  background: #ff8415;
  width: 2.14rem;
  height: 2.14rem;
  align-items: center;
  justify-content: center;
  padding-top: 0.29rem;
}

div.page_content div.page_notice span {
  font-size: 1.71rem;
  color: #333;
  vertical-align: middle;
  margin-left: 0.71rem;
  font-family: noto_M;
}

div.page_content div.book_list_wrapper {
  background: #f8f8f8;
  max-width: 110.36rem;
  margin: 0 auto;
  border-radius: 1.07rem;
  padding: 2rem;
  position: relative;
  padding-bottom: 4.5rem;
}

div.page_content
  div.book_list_wrapper
  div.book_list_inner
  div.swiper-container.book_list_slider {
  height: auto;
}

div.page_content
  div.book_list_wrapper
  div.book_list_inner
  div.swiper-container.book_list_slider
  div.swiper-wrapper {
  height: auto;
}

div.page_content
  div.book_list_wrapper
  div.book_list_inner
  div.swiper-container.book_list_slider
  div.swiper-wrapper
  div.swiper-slide {
  height: auto;
}

div.book_img {
  width: 3.93rem;
  height: 100%;
  position: absolute;
  right: 0.29rem;
  z-index: 4;
  max-height: 53.21rem;
}

div.book_img ul.book_img_list {
  width: 100%;
  font-size: 0;
}

div.book_img ul.book_img_list li {
  background: #ffffff;
  box-shadow: 0rem 0rem 0.29rem rgba(0, 0, 0, 0.25);
  border-radius: 0rem 2.14rem 2.14rem 0rem;
  width: 100%;
  height: 100%;
  position: absolute;
}

div.book_img ul.book_img_list li:nth-of-type(1) {
  right: -0.86rem;
  z-index: 8;
}

div.book_img ul.book_img_list li:nth-of-type(2) {
  right: -1.29rem;
  z-index: 7;
}

div.book_img ul.book_img_list li:nth-of-type(3) {
  right: -1.71rem;
  z-index: 6;
}

div.book_img ul.book_img_list li:nth-of-type(4) {
  right: -2.14rem;
  z-index: 5;
}

.page_content_inner {
  background: #fff;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  max-height: 53.21rem;
  border-radius: 2.14rem;
  position: relative;
  z-index: 6;
  box-shadow: 0rem 0rem 0.29rem rgba(0, 0, 0, 0.25);
  padding-bottom: 40rem;
}

li.rank a {
  background: url(../images/common/icon_rank.png) no-repeat;
  background-position: center;
}

li.avatar a {
  background: url(../images/common/icon_user_2.png) no-repeat;
  background-position: center;
}

li.logoff a {
  background: url(../images/common/icon_logoff.png) no-repeat;
  background-position: center;
}

li.home a {
  background: url(../images/common/icon_home.png) no-repeat;
  background-position: center;
}

.background_blue {
  background-color: #f8faff;
}

.background_blue > li {
  background-color: #f8faff !important;
}

div.popup_wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
  display: none;
}

div.popup_wrapper div.popup_box {
  position: relative;
  z-index: 6;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 3.57rem;
  border: 1.07rem solid #e9e9e9;
  flex-direction: column;
  opacity: 1;
  margin-top: -3.21rem;
  padding: 3rem 5rem;
}

div.popup_wrapper div.popup_box div.popup_close {
  width: 5.71rem;
  height: 5.71rem;
  position: absolute;
  right: -2.86rem;
  top: -3.07rem;
  background: url(../images/common/btn_close.png) no-repeat;
  background-position: center;
  background-size: cover;
  box-shadow: 0rem 0.29rem 0.29rem rgba(0, 0, 0, 0.25);
  border-radius: 100%;
  cursor: pointer;
}

div.popup_wrapper div.popup_box h1.popup_text {
  font-size: 2.57rem;
  line-height: 4.07rem;
  text-align: center;
}

div.popup_wrapper div.popup_box > button {
  font-size: 2.5rem;
  color: #fff;
  background: #ff7a00;
  border-radius: 0.57rem;
  padding: 0.8rem 3.75rem;
  margin-top: 2.5rem;
}

div.popup_wrapper div.background_black {
  position: absolute;
  background: #000;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  z-index: 5;
}

div.popup_btn_wrapper {
  font-size: 0;
  margin-top: 3.43rem;
}

div.popup_btn_wrapper button.keep_going {
  width: 15rem;
  display: inline-block;
  vertical-align: middle;
  font-size: 1.57rem !important;
  height: 5.36rem;
  background: #ff7a00;
  color: #fff;
  border-radius: 0.57rem;
  margin-right: 1.14rem;
}

div.popup_btn_wrapper button.begin_first {
  width: 15rem;
  display: inline-block;
  vertical-align: middle;
  font-size: 1.57rem !important;
  height: 5.36rem;
  background: #c4c4c4;
  color: #fff;
  border-radius: 0.57rem;
}

div.load_wrapper {
  background: #0f69c3;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 10rem;
}

div.load_wrapper img {
  position: absolute;
}

div.load_wrapper img:nth-of-type(1) {
  margin-top: 8rem;
  z-index: 6;
  margin-right: 6rem;
  transform: scale(0);
  width: 9.79rem;
}

div.load_wrapper img:nth-of-type(2) {
  margin-left: 1rem;
  transform: scale(0);
  width: 9.14rem;
}

div.load_wrapper h1 {
  text-align: center;
  margin-top: 18rem;
  font-size: 2.14rem;
  color: #fff;
  text-shadow: 0rem 0.14rem 0.07rem rgba(0, 0, 0, 0.25);
}

div.popup_box_type2 {
  width: 57.86rem;
  position: relative;
  z-index: 6;
  height: 43.93rem;
  background: url(../images/common/start_back.png) no-repeat;
  background-position: center;
  background-size: 98%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 14.29rem;
  opacity: 0;
}

div.popup_box_type2 div.popup_close {
  width: 9.21rem;
  height: 9.14rem;
  position: absolute;
  right: 3.5rem;
  top: 1rem;
  background: url(../images/common/btn_close_2.png) no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 100%;
  cursor: pointer;
}

div.popup_box_type2 span {
  font-size: 5rem;
  line-height: 3.93rem;
  /* identical to box height, or 79% */

  text-align: center;
  color: #ff7a00;
  text-shadow: 0rem 0.14rem 0.14rem rgba(0, 0, 0, 0.25);
}

div.popup_box_type2 h1.popup_text {
  font-size: 2.57rem;
  line-height: 3.79rem;
  /* or 145% */

  text-align: center;
  margin: 3.5rem 0;
}

div.popup_box_type2 div.popup_btn_wrapper_2 button {
  width: 14.29rem;
  height: 5.3rem;
  background: #ff7a00;
  box-shadow: 0rem 0.14rem 0.14rem rgba(0, 0, 0, 0.25);
  border-radius: 0.57rem;
  font-family: noto_B;
  font-size: 2.71rem;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
}

div.popup_wrapper_2 {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

div.popup_wrapper_2 div.popup_box_type3 {
  width: 52rem;
  height: 31rem;
  position: relative;
  z-index: 6;
  background: #ffffff;
  border: 1rem solid #e9e9e9;
  box-sizing: border-box;
  border-radius: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  opacity: 0;
}

div.popup_wrapper_2 div.popup_box_type3 span {
  background: #ff7900;
  border-radius: 2rem;
  font-size: 4rem;
  color: #fff;
  padding: 1rem 2rem;
}

div.popup_wrapper_2 div.popup_box_type3 h1 {
  font-size: 3.5rem;
  margin: 0 auto;
  line-height: 6rem;
  /* or 157% */

  text-align: center;
  padding-top: 3rem;
  margin-bottom: 2rem;
  width: 90%;
}

div.popup_wrapper_2 div.background_black {
  position: absolute;
  z-index: 4;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.5;
}

.mobile_asset {
  display: none !important;
}

.m_menu_wrapper {
  display: none;
}

@media (max-width: 1700px) {
  .page_content_inner {
    background: #fff;
    max-width: 128.57rem;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    max-height: 53.21rem;
    border-radius: 2.14rem;
    position: relative;
    z-index: 6;
    box-shadow: 0rem 0rem 0.29rem rgba(0, 0, 0, 0.25);
    padding: 0;
  }
}

@media (max-width: 1500px) {
  .wrap {
    width: 100%;
    height: 100vh;
    background-color: #2e86df;
    overflow: hidden;
    font-size: 0;
  }
  div.book_img {
    max-height: 100%;
  }
  div.alert {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 9999;
    top: 0;
  }

  div.alert div.alert_inner {
    width: auto;
    height: 8.57rem;
    background: rgba(255, 122, 0, 0.8);
    border-radius: 7.14rem;
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 0 3rem;
  }

  .read_alert > .alert_inner {
    background: rgba(212, 164, 106, 0.8) !important;
  }

  div.alert div.alert_inner img {
    margin-right: 2rem;
  }

  div.alert div.alert_inner h1 {
    text-shadow: 0rem 0.14rem 0.14rem rgba(0, 0, 0, 0.26);
    font-size: 2.43rem;
    color: #fff;
  }

  div.page_header {
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 0;
  }

  div.page_header div.user_left {
    display: inline-block;
    font-size: 0;
    vertical-align: middle;
    min-width: 25.36rem;
  }

  div.page_header div.user_left p.user_photo {
    width: 7.86rem;
    height: 7.86rem;
    display: inline-block;
    vertical-align: middle;
    background: #fff;
    border: 0.36rem solid #ff8515;
    box-shadow: 0rem 0.29rem 0.29rem rgba(0, 0, 0, 0.25);
    border-radius: 1.07rem;
    margin-right: 1.25rem;
    font-size: 0;
    background: url(../images/common/avatar_3.png) no-repeat;
    background-size: 100%;
    background-position: center top;
    background-color: #fff;
    border: 0.36rem solid #ecde06;
  }

  div.page_header div.user_left div.col {
    display: inline-block;
    vertical-align: middle;
  }

  div.page_header div.user_left div.col h1.user_name {
    font-size: 1.71rem;
    color: #fff;
    text-shadow: 0rem 0.14rem 0.07rem rgba(0, 0, 0, 0.25);
  }

  div.mic_permission p.title {
    font-size: 1.71rem;
    color: #fff;
    text-shadow: 0rem 0.14rem 0.07rem rgba(0, 0, 0, 0.25);
  }

  div.page_header div.user_left div.col div.row {
    margin-top: 0.75rem;
  }

  div.page_header div.user_left div.col div.row p {
    box-shadow: 0rem 0.14rem 0.07rem rgba(0, 0, 0, 0.25);
    width: 7.14rem;
    height: 2.14rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 7.14rem;
    background: #fff;
    color: #2e86df;
    font-size: 1.71rem;
    vertical-align: middle;
    margin-top: 0;
  }

  div.page_header div.user_left div.col div.row span.dot_number {
    font-size: 1.71rem;
    color: #fff;
    text-shadow: 0rem 0.14rem 0.07rem rgba(0, 0, 0, 0.25);
    vertical-align: middle;
    margin-left: 0.5rem;
    display: inline-flex;
  }

  div.page_header div.page_name h1 {
    font-size: 4rem;
    text-align: center;
    color: #fff;
    text-shadow: 0rem 0.14rem 0.07rem rgba(0, 0, 0, 0.25);
  }

  div.page_header div.btn_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div.page_header div.btn_wrapper ul.btn_list {
    font-size: 0;
    text-align: right;
  }

  div.page_header div.btn_wrapper ul.btn_list li {
    width: 7.86rem;
    height: 7.86rem;
    display: inline-block;
    vertical-align: middle;
    background: #0f69c3;
    border: 0.36rem solid #fff;
    box-shadow: 0rem 0.29rem 0.29rem rgba(0, 0, 0, 0.25);
    border-radius: 1.07rem;
    font-size: 0;
    cursor: pointer;
  }

  div.page_header div.btn_wrapper ul.btn_list li:nth-of-type(2) {
    margin: 0 0.8rem;
  }

  div.page_header div.btn_wrapper ul.btn_list li a {
    display: flex;
    width: 100%;
    height: 100%;
  }

  div.page_content {
    max-width: 128.57rem;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    position: relative;
  }

  div.page_content div.page_notice {
    text-align: center;
    padding: 1.5rem 0;
    font-size: 0;
  }

  div.page_content div.page_notice p {
    display: inline-flex;
    font-size: 1.79rem;
    vertical-align: middle;
    color: #fff;
    border-radius: 0.5rem;
    background: #ff8415;
    width: 2.14rem;
    height: 2.14rem;
    align-items: center;
    justify-content: center;
    padding-top: 0.29rem;
  }

  .icon_notice {
    display: inline-flex;
    font-size: 1.79rem;
    vertical-align: middle;
    color: #fff;
    border-radius: 0.5rem;
    background: #ff8415;
    width: 2.14rem;
    height: 2.14rem;
    align-items: center;
    justify-content: center;
    padding-top: 0.29rem;
  }

  div.page_content div.page_notice span {
    font-size: 1.71rem;
    color: #333;
    vertical-align: middle;
    margin-left: 0.71rem;
    font-family: noto_M;
  }

  div.page_content div.book_list_wrapper {
    background: #f8f8f8;
    max-width: 110.36rem;
    margin: 0 auto;
    border-radius: 1.07rem;
    padding: 2rem;
    position: relative;
    padding-bottom: 4.5rem;
  }

  div.page_content
    div.book_list_wrapper
    div.book_list_inner
    div.swiper-container.book_list_slider {
    height: auto;
  }

  div.page_content
    div.book_list_wrapper
    div.book_list_inner
    div.swiper-container.book_list_slider
    div.swiper-wrapper {
    height: auto;
  }

  div.page_content
    div.book_list_wrapper
    div.book_list_inner
    div.swiper-container.book_list_slider
    div.swiper-wrapper
    div.swiper-slide {
    height: auto;
  }

  div.book_img {
    width: 3.93rem;
    height: 100%;
    position: absolute;
    right: 0.29rem;
    z-index: 4;
  }

  div.book_img ul.book_img_list {
    width: 100%;
    font-size: 0;
  }

  div.book_img ul.book_img_list li {
    background: #ffffff;
    box-shadow: 0rem 0rem 0.29rem rgba(0, 0, 0, 0.25);
    border-radius: 0rem 2.14rem 2.14rem 0rem;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  div.book_img ul.book_img_list li:nth-of-type(1) {
    right: -0.86rem;
    z-index: 8;
  }

  div.book_img ul.book_img_list li:nth-of-type(2) {
    right: -1.29rem;
    z-index: 7;
  }

  div.book_img ul.book_img_list li:nth-of-type(3) {
    right: -1.71rem;
    z-index: 6;
  }

  div.book_img ul.book_img_list li:nth-of-type(4) {
    right: -2.14rem;
    z-index: 5;
  }

  .page_content_inner {
    background: #fff;
    max-width: 128.57rem;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    max-height: 53.21rem;
    border-radius: 2.14rem;
    position: relative;
    z-index: 6;
    box-shadow: 0rem 0rem 0.29rem rgba(0, 0, 0, 0.25);
    padding: 0;
  }

  li.rank a {
    background: url(../images/common/icon_rank.png) no-repeat;
    background-position: center;
  }

  li.avatar a {
    background: url(../images/common/icon_user_2.png) no-repeat;
    background-position: center;
  }

  li.logoff a {
    background: url(../images/common/icon_logoff.png) no-repeat;
    background-position: center;
  }

  li.home a {
    background: url(../images/common/icon_home.png) no-repeat;
    background-position: center;
  }

  .background_blue {
    background-color: #f8faff;
  }

  .background_blue > li {
    background-color: #f8faff !important;
  }

  div.popup_wrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
    display: none;
  }

  div.popup_wrapper div.popup_box {
    position: relative;
    z-index: 6;
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 3.57rem;
    border: 8px solid #e9e9e9;
    flex-direction: column;
    opacity: 1;
    margin-top: -6.21rem;
    padding: 2rem 4rem;
  }

  div.popup_wrapper div.popup_box div.popup_close {
    width: 5.71rem;
    height: 5.71rem;
    position: absolute;
    right: -2.86rem;
    top: -3.07rem;
    background: url(../images/common/btn_close.png) no-repeat;
    background-position: center;
    background-size: cover;
    box-shadow: 0rem 0.29rem 0.29rem rgba(0, 0, 0, 0.25);
    border-radius: 100%;
    cursor: pointer;
  }

  div.popup_wrapper div.popup_box h1.popup_text {
    font-size: 2rem;
    line-height: 3.5rem;
    text-align: center;
  }

  div.popup_wrapper div.popup_box > button {
    font-size: 2rem;
    color: #fff;
    background: #ff7a00;
    border-radius: 0.57rem;
    padding: 0.8rem 3.75rem;
    margin-top: 2.5rem;
  }

  div.popup_wrapper div.background_black {
    position: absolute;
    background: #000;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    z-index: 5;
  }

  div.popup_btn_wrapper {
    font-size: 0;
    margin-top: 3.43rem;
  }

  div.popup_btn_wrapper button.keep_going {
    width: 15rem;
    display: inline-block;
    vertical-align: middle;
    font-size: 1.57rem !important;
    height: 5.36rem;
    background: #ff7a00;
    color: #fff;
    border-radius: 0.57rem;
    margin-right: 1.14rem;
  }

  div.popup_btn_wrapper button.begin_first {
    width: 15rem;
    display: inline-block;
    vertical-align: middle;
    font-size: 1.57rem !important;
    height: 5.36rem;
    background: #c4c4c4;
    color: #fff;
    border-radius: 0.57rem;
  }

  div.load_wrapper {
    background: #0f69c3;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 10rem;
  }

  div.load_wrapper img {
    position: absolute;
  }

  div.load_wrapper img:nth-of-type(1) {
    margin-top: 8rem;
    z-index: 6;
    margin-right: 6rem;
    transform: scale(0);
    width: 9.79rem;
  }

  div.load_wrapper img:nth-of-type(2) {
    margin-left: 1rem;
    transform: scale(0);
    width: 9.14rem;
  }

  div.load_wrapper h1 {
    text-align: center;
    margin-top: 18rem;
    font-size: 2.14rem;
    color: #fff;
    text-shadow: 0rem 0.14rem 0.07rem rgba(0, 0, 0, 0.25);
  }

  div.popup_box_type2 {
    width: 57.86rem;
    position: relative;
    z-index: 6;
    height: 43.93rem;
    background: url(../images/common/start_back.png) no-repeat;
    background-position: center;
    background-size: 98%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 14.29rem;
    opacity: 0;
  }

  div.popup_box_type2 div.popup_close {
    width: 9.21rem;
    height: 9.14rem;
    position: absolute;
    right: 3.5rem;
    top: 1rem;
    background: url(../images/common/btn_close_2.png) no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 100%;
    cursor: pointer;
  }

  div.popup_box_type2 span {
    font-size: 5rem;
    line-height: 3.93rem;
    /* identical to box height, or 79% */
    text-align: center;
    color: #ff7a00;
    text-shadow: 0rem 0.14rem 0.14rem rgba(0, 0, 0, 0.25);
  }

  div.popup_box_type2 h1.popup_text {
    font-size: 2.57rem;
    line-height: 3.79rem;
    /* or 145% */
    text-align: center;
    margin: 3.5rem 0;
  }

  div.popup_box_type2 div.popup_btn_wrapper_2 button {
    width: 14.29rem;
    height: 5.3rem;
    /* left: 61.43rem;
    top: 46.36rem; */
    background: #ff7a00;
    box-shadow: 0rem 0.14rem 0.14rem rgba(0, 0, 0, 0.25);
    border-radius: 0.57rem;
    font-family: noto_B;
    font-size: 2.71rem;
    text-align: center;
    letter-spacing: 0.01em;
    color: #ffffff;
  }

  div.popup_wrapper_2 {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
  }

  div.popup_wrapper_2 div.popup_box_type3 {
    width: 70%;
    height: auto;
    position: relative;
    z-index: 6;
    background: #ffffff;
    border: 1rem solid #e9e9e9;
    box-sizing: border-box;
    border-radius: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    opacity: 0;
    padding: 4rem;
  }

  div.popup_wrapper_2 div.popup_box_type3 span {
    background: #ff7900;
    border-radius: 1rem;
    font-size: 3rem;
    color: #fff;
    padding: 1rem 2rem;
  }

  div.popup_wrapper_2 div.popup_box_type3 h1 {
    font-size: 3.5rem;
    margin: 0 auto;
    line-height: 6rem;
    /* or 157% */
    text-align: center;
    padding-top: 3rem;
    margin-bottom: 2rem;
    width: 90%;
  }

  div.popup_wrapper_2 div.background_black {
    position: absolute;
    z-index: 4;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.5;
  }

  .mobile_asset {
    display: none !important;
  }

  div.detail_btn_wrapper div.detail_btn_inner a.long img {
    width: 33.57rem;
  }

  div.detail_btn_wrapper div.detail_btn_inner a.short_revert img {
    width: 5.71rem;
  }

  div.detail_btn_wrapper div.detail_btn_inner a.short_normal img {
    width: 5.71rem;
  }

  .page_content_inner {
    background: #fff;
    max-width: 128.57rem;
    margin: 0 auto;
    width: 95.5%;
    height: 81%;
    max-height: none;
    border-radius: 2.14rem;
    position: relative;
    z-index: 6;
    box-shadow: 0rem 0rem 0.29rem rgba(0, 0, 0, 0.25);
  }

  div.book_img {
    width: 3.93rem;
    height: 81%;
    position: absolute;
    right: 3.29rem;
    z-index: 4;
  }

  div.page_header {
    width: 93%;
    margin: 0 auto;
    padding: 1rem 0;
  }

  div.page_header div.btn_wrapper ul.btn_list li a {
    background-position: center;
    background-size: 65%;
  }

  div.page_header div.btn_wrapper ul.btn_list li {
    width: 6.86rem;
    height: 6.86rem;
    display: inline-block;
    vertical-align: middle;
    background: #0f69c3;
    border: 0.36rem solid #fff;
    box-shadow: 0rem 0.29rem 0.29rem rgba(0, 0, 0, 0.25);
    border-radius: 1.07rem;
    font-size: 0;
    cursor: pointer;
  }

  div.page_header div.page_name h1 {
    font-size: 3.25rem;
    text-align: center;
    color: #fff;
    text-shadow: 0rem 0.14rem 0.07rem rgba(0, 0, 0, 0.25);
  }
}

@media (max-width: 1023px) {
  div.m_menu_wrapper {
    position: fixed;
    z-index: 7;
    top: 0;
    left: 0;
  }

  div.m_menu_wrapper p.m_menu_icon {
    width: 70px;
    height: 70px;
    background: url(../images/mobile/m_icon_menu.png) no-repeat;
    background-position: center;
    background-size: cover;
    margin-top: 0;
    cursor: pointer;
    position: relative;
    z-index: 4;
  }

  div.m_menu_wrapper div.m_menu_inner {
    position: fixed;
    width: 35.3rem;
    height: 100%;
    background: #fff;
    top: 0;
    left: -40.3rem;
    z-index: 6;
    transition: 0.5s;
  }

  div.m_menu_wrapper div.m_menu_inner div.top {
    background: #2e85dc;
    padding: 3rem;
    padding-bottom: 5rem;
    position: relative;
    z-index: 5;
  }

  div.m_menu_wrapper div.m_menu_inner div.top p.m_my_icon {
    background: url(../images/common/avatar_3.png) no-repeat;
    width: 7rem;
    height: 7rem;
    display: inline-block;
    margin-top: 0;
    background-position: center;
    background-size: cover;
    border: 3px solid #ecde06;
    background-color: #fff;
    border-radius: 1rem;
    vertical-align: middle;
    margin-right: 2rem;
  }

  div.m_menu_wrapper div.m_menu_inner div.top div {
    display: inline-block;
    vertical-align: middle;
  }

  div.m_menu_wrapper div.m_menu_inner div.top > div > h1 {
    font-size: 1.7rem;
    color: #fff;
    margin-top: 0;
  }

  div.m_menu_wrapper div.m_menu_inner div.top div div {
    margin-top: 0.65rem;
  }

  div.m_menu_wrapper div.m_menu_inner div.top > div > div > h1 {
    color: #2e85dc;
    font-size: 1.5rem;
    background: #fff;
    padding: 0.5rem 0.8rem;
    border-radius: 10rem;
    display: inline-block;
    vertical-align: middle;
    margin-top: 0;
  }

  div.m_menu_wrapper div.m_menu_inner div.top div div h2 {
    margin-top: 0;
    font-size: 1.7rem;
    display: inline-block;
    vertical-align: middle;
    color: #fff;
    margin-left: 0.75rem;
  }

  div.m_menu_wrapper div.m_menu_inner div.bottom ul.bottom_list {
    padding: 0 3rem;
    margin-top: -4rem;
    position: relative;
    z-index: 6;
  }

  div.m_menu_wrapper div.m_menu_inner div.bottom ul.bottom_list li {
    display: inline-flex;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    width: 14rem;
    height: 8rem;
    border-radius: 1rem;
    background: #ffffff;
    margin-right: 1rem;
    margin-top: 1rem;
    align-items: center;
    justify-content: center;
    vertical-align: top;
    flex-direction: column;
  }

  div.m_menu_wrapper
    div.m_menu_inner
    div.bottom
    ul.bottom_list
    li:nth-of-type(2n) {
    margin-right: 0;
  }

  div.m_menu_wrapper div.m_menu_inner div.bottom ul.bottom_list li img {
    width: 4rem;
  }

  div.m_menu_wrapper div.m_menu_inner div.bottom ul.bottom_list li h1 {
    font-size: 1.5rem;
    font-family: 'noto_L';
    padding-top: 0;
    color: #2e85dc;
  }

  div.page_header {
    display: none;
  }

  div.page_content {
    max-width: 128.57rem;
    margin: 0 auto;
    width: 100%;
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .page_content_inner {
    background: #fff;
    max-width: 128.57rem;
    margin: 0 auto;
    width: 91%;
    height: 94%;
    max-height: none;
    border-radius: 2.14rem;
    position: relative;
    z-index: 6;
    box-shadow: 0rem 0rem 0.29rem rgba(0, 0, 0, 0.25);
  }

  div.book_img {
    width: 3.93rem;
    height: 94%;
    position: absolute;
    right: 3.29rem;
    z-index: 4;
  }

  div.page_content div.book_list_wrapper {
    background: #f8f8f8;
    max-width: 110.36rem;
    margin: 0 auto;
    border-radius: 1.07rem;
    padding: 1rem;
    position: relative;
    padding-bottom: 3rem;
  }

  div.page_content div.page_notice span {
    font-size: 12px;
  }

  div.page_content div.page_notice p {
    display: inline-flex;
    font-size: 12px;
    vertical-align: middle;
    color: #fff;
    border-radius: 0.3rem;
    background: #ff8415;
    width: 1.5rem;
    height: 1.5rem;
    align-items: center;
    justify-content: center;
    padding-top: 0.29rem;
  }

  div.page_content div.page_notice {
    text-align: center;
    padding: 1rem 0;
    font-size: 0;
  }

  .m_menu_wrapper .background_black {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #000;
    opacity: 0.5;
    z-index: 4;
    display: none;
  }

  .m_menu_off {
    position: absolute;
    background: url(../images/mobile/icon_menu_off.png) no-repeat;
    background-position: center;
    background-size: cover;
    width: 7rem;
    height: 7rem;
    right: -3rem;
    cursor: pointer;
  }

  .active .m_menu_inner {
    left: 0 !important;
  }

  .active .background_black {
    display: block !important;
  }
}

@media (max-width: 900px) {
  .m_menu_wrapper {
    display: block;
  }
}

@media (max-width: 700px) {
  div.popup_wrapper_2 div.popup_box_type3 {
    width: 80%;
    height: 85%;
    position: relative;
    z-index: 6;
    background: #ffffff;
    border: 0.5rem solid #e9e9e9;
    box-sizing: border-box;
    border-radius: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    opacity: 1;
  }

  div.popup_wrapper_2 div.popup_box_type3 h1 {
    font-size: 2.5rem !important;
    margin: 0 auto;
    line-height: 6rem;
    text-align: center;
    padding-top: 1rem;
    margin-bottom: 2rem;
    width: 90%;
  }

  div.popup_wrapper_2 div.popup_box_type3 span {
    background: #ff7900;
    border-radius: 1rem;
    font-size: 2rem;
    color: #fff;
    padding: 1rem 2rem;
  }

  div.popup_box_type2 {
    width: 67%;
    position: relative;
    z-index: 6;
    height: 100%;
    background: url(../images/common/start_back.png) no-repeat;
    background-position: center;
    background-size: 98%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 17rem;
    opacity: 0;
  }

  div.popup_box_type2 div.popup_close {
    width: 6rem;
    height: 6rem;
    position: absolute;
    right: 1.5rem;
    top: 3rem;
    background: url(../images/common/btn_close_2.png) no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 100%;
    cursor: pointer;
  }
}

@media (max-width: 660px) {
  div.m_menu_wrapper {
    position: fixed;
    z-index: 7;
    top: 0;
    left: 0;
  }

  div.m_menu_wrapper p.m_menu_icon {
    width: 70px;
    height: 70px;
    background: url(../images/mobile/m_icon_menu.png) no-repeat;
    background-position: center;
    background-size: cover;
    margin-top: 0;
    cursor: pointer;
    position: relative;
    z-index: 4;
  }

  div.m_menu_wrapper div.m_menu_inner {
    position: fixed;
    width: 31.3rem;
    height: 100%;
    background: #fff;
    top: 0;
    left: -40.3rem;
    z-index: 6;
    transition: 0.5s;
  }

  div.m_menu_wrapper div.m_menu_inner div.top {
    background: #2e85dc;
    padding: 2rem;
    padding-bottom: 4rem;
    position: relative;
    z-index: 5;
  }

  div.m_menu_wrapper div.m_menu_inner div.top p.m_my_icon {
    background: url(../images/common/avatar_3.png) no-repeat;
    width: 7rem;
    height: 7rem;
    display: inline-block;
    margin-top: 0;
    background-position: center;
    background-size: cover;
    border: 3px solid #ecde06;
    background-color: #fff;
    border-radius: 1rem;
    vertical-align: middle;
    margin-right: 2rem;
  }

  div.m_menu_wrapper div.m_menu_inner div.top div {
    display: inline-block;
    vertical-align: middle;
  }

  div.m_menu_wrapper div.m_menu_inner div.top > div > h1 {
    font-size: 1.5rem;
    color: #fff;
    margin-top: 0;
  }

  div.m_menu_wrapper div.m_menu_inner div.top div div {
    margin-top: 0.65rem;
  }

  div.m_menu_wrapper div.m_menu_inner div.top > div > div > h1 {
    color: #2e85dc;
    font-size: 1.35rem;
    background: #fff;
    padding: 0.5rem 0.8rem;
    border-radius: 10rem;
    display: inline-block;
    vertical-align: middle;
    margin-top: 0;
  }

  div.m_menu_wrapper div.m_menu_inner div.top div div h2 {
    margin-top: 0;
    font-size: 1.5rem;
    display: inline-block;
    vertical-align: middle;
    color: #fff;
    margin-left: 0.75rem;
  }

  div.m_menu_wrapper div.m_menu_inner div.bottom ul.bottom_list {
    padding: 0 2rem;
    margin-top: -4rem;
    position: relative;
    z-index: 6;
  }

  div.m_menu_wrapper div.m_menu_inner div.bottom ul.bottom_list li {
    display: inline-flex;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    width: 13rem;
    height: 7rem;
    border-radius: 1rem;
    background: #ffffff;
    margin-right: 1rem;
    margin-top: 1rem;
    align-items: center;
    justify-content: center;
    vertical-align: top;
    flex-direction: column;
  }

  div.m_menu_wrapper
    div.m_menu_inner
    div.bottom
    ul.bottom_list
    li:nth-of-type(2n) {
    margin-right: 0;
  }

  div.m_menu_wrapper div.m_menu_inner div.bottom ul.bottom_list li img {
    width: 3rem;
  }

  div.m_menu_wrapper div.m_menu_inner div.bottom ul.bottom_list li h1 {
    font-size: 1.35rem;
    font-family: 'noto_L';
    padding-top: 0;
    color: #2e85dc;
  }
}

@media screen and (min-width: 500px) and (max-width: 900px) {
  div.popup_box_type2 span {
    font-size: 3rem;
  }

  div.popup_box_type2 h1.popup_text {
    font-size: 1.57rem;
    line-height: 2.5rem;
    text-align: center;
    margin: 1.5rem 0;
  }

  div.popup_box_type2 div.popup_btn_wrapper_2 button {
    width: 10rem;
    height: 4rem;
    background: #ff7a00;
    box-shadow: 0rem 0.14rem 0.14rem rgba(0, 0, 0, 0.25);
    border-radius: 0.57rem;
    font-family: noto_B;
    font-size: 1.8rem;
    text-align: center;
    letter-spacing: 0.01em;
    color: #ffffff;
  }

  div.popup_box_type2 div.popup_close {
    width: 7rem;
    height: 7rem;
    position: absolute;
    right: 1.5rem;
    top: 4rem;
    background: url(../images/common/btn_close_2.png) no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 100%;
    cursor: pointer;
  }

  div.popup_box_type2 {
    width: 60%;
    position: relative;
    z-index: 6;
    height: 100%;
    background: url(../images/common/start_back.png) no-repeat;
    background-position: center;
    background-size: 98%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 18.29rem;
    opacity: 0;
  }
}

@media screen and (min-width: 800px) and (max-width: 900px) {
  div.popup_box_type2 {
    width: 46%;
  }
  div.popup_box_type2 div.popup_close {
    top: 5rem;
  }
}
