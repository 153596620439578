div.mypage_inner {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0 2.5rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
}

div.mypage_inner div.mypage_infoArea {
    vertical-align: top;
    display: inline-block;
    width: 100%;
}

div.mypage_inner div.mypage_infoArea h1.title {
    font-size: 2rem;
    color: #333;
    margin-bottom: 2rem;
    display: block;
}

div.mypage_inner div.mypage_infoArea div.row {
    display: flex;
    gap: 3rem;
}

div.mypage_inner div.mypage_infoArea div.row div.bookImg {
    width: 16rem;
    height: 23rem;
    display: inline-block;
    background-position: center;
    background-size: cover;
    border-radius: 0.36rem;
    box-shadow: 0rem 0.29rem 0.29rem rgba(0, 0, 0, 0.25);
    vertical-align: top;
}

div.mypage_inner div.mypage_infoArea div.row div {
    display: inline-block;
    vertical-align: top;
}

div.mypage_inner div.mypage_infoArea div.row div div.row {
    display: block;
    font-size: 0;
}

div.mypage_inner div.mypage_infoArea div.row div div.row:nth-of-type(3) {
    margin-top: 1.43rem;
}

div.mypage_inner div.mypage_infoArea div.row div div.row h1 {
    font-size: 1.5rem;
    margin-bottom: 0.7rem;
}

div.mypage_inner div.mypage_infoArea div.row div div.row:nth-of-type(2) {
    margin-top: 2.5rem;
}

.color_orange {
    color: #ff7a00;
}

div.mypage_inner div.mypage_infoArea div.row div div.row h2 {
    font-size: 1.5rem;
    color: #4f74c5;
    width: 26.43rem;
    height: 4rem;
    background: #fff;
    border: 0.21rem solid #e8edf8;
    border-radius: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 1.5rem;
}

div.mypage_inner div.mypage_infoArea div.row div div.row div.inner_row {
    display: flex;
}

div.mypage_inner div.mypage_infoArea div.row div div.row div.inner_row p {
    font-size: 1.3rem;
    color: #4f74c5;
    width: 11.43rem;
    height: 4rem;
    background: #fff;
    border: 0.21rem solid #e8edf8;
    border-radius: 10px;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
}

div.mypage_inner div.mypage_infoArea div.row div div.row div.inner_row span {
    font-size: 1.71rem;
    vertical-align: middle;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #4f74c5;
    padding: 0 1.14rem;
}

div.mypage_inner div.mypage_infoArea div.row div div.row h3 {
    display: inline-block;
    vertical-align: middle;
    font-size: 1.35rem;
    margin-left: 0.57rem;
    margin-top: 0.3rem;
}

div.mypage_inner div.mypage_infoArea div.row div div.row h3 span {
    color: #ff8415;
}

div.mypage_inner div.mypage_processArea {
    vertical-align: top;
    display: inline-block;
}

div.mypage_inner div.mypage_processArea h1.title {
    font-size: 2rem;
    color: #333;
    margin-bottom: 1.43rem;
}

div.mypage_inner div.mypage_processArea div.process_scroller {
    overflow-y: auto;
    height: 37.14rem;
    overflow-x: hidden;
    padding: 2rem 1rem 0 2rem;
}

div.mypage_inner div.mypage_processArea div.process_scroller ul.process_list.col6 {
    font-size: 0;
    width: calc(9.29rem * 6 + 1.43rem * 5);
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 4rem 2rem;
}

div.mypage_inner div.mypage_processArea div.process_scroller ul.process_list.col6 li {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 9.29rem;
    height: auto;
    flex-direction: column;
    vertical-align: top;
    cursor: pointer;
}

div.mypage_inner div.mypage_processArea div.process_scroller ul.process_list.col6 li.completed p.number {
    background-color: #ffffff;
    border: 0.21rem solid #fbc08b;
    box-sizing: border-box;
    border-radius: 100%;
    display: block;
    position: absolute;
    top: -1.14rem;
    left: -1.14rem;
    width: 2.86rem;
    height: 2.86rem;
    z-index: 6;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #878787;
    font-size: 1.57rem;
}

div.mypage_inner div.mypage_processArea div.process_scroller ul.process_list.col6 li.completed a {
    opacity: 0.5;
}

div.mypage_inner div.mypage_processArea div.process_scroller ul.process_list.col6 li.completed p.mark {
    width: 5rem;
    height: 5rem;
    background: url(../images/common/icon_complete.png) no-repeat;
    opacity: 0.5;
    position: absolute;
    cursor: pointer;
}

div.mypage_inner div.mypage_processArea div.process_scroller ul.process_list.col6 li.doing p.number {
    background-color: #ffffff;
    border: 0.21rem solid #ff8515;
    box-sizing: border-box;
    border-radius: 100%;
    position: absolute;
    top: -1.14rem;
    left: -1.14rem;
    width: 2.86rem;
    height: 2.86rem;
    z-index: 6;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333;
    font-size: 1.57rem;
}

div.mypage_inner div.mypage_processArea div.process_scroller ul.process_list.col6 li.locked p.number {
    background-color: #ffffff;
    border: 0.21rem solid #fbc08b;
    box-sizing: border-box;
    border-radius: 100%;
    display: block;
    position: absolute;
    top: -1.14rem;
    left: -1.14rem;
    width: 2.86rem;
    height: 2.86rem;
    z-index: 6;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #878787;
    font-size: 1.57rem;
    margin-top: 0;
    padding-top: 4px;
    padding-right: 1px;
}

div.mypage_inner div.mypage_processArea div.process_scroller ul.process_list.col6 li.locked a {
    opacity: 0.5;
}

div.mypage_inner div.mypage_processArea div.process_scroller ul.process_list.col6 li a {
    font-size: 1.86rem;
    text-align: center;
    width: 100%;
    height: 9.29rem;
    display: flex;
    align-items: center;
    justify-content: center;
    word-break: keep-all;
    border: 0.21rem solid #ffcc9d;
    box-sizing: border-box;
    border-radius: 0.71rem;
    background: #ff8515;
    color: #fff;
}

div.mypage_inner div.mypage_processArea div.process_scroller::-webkit-scrollbar {
    width: 1.07rem;
    background-color: #efefef;
    border-radius: 1.07rem;
}

div.mypage_inner div.mypage_processArea div.process_scroller::-webkit-scrollbar-thumb {
    background-color: #ff7b01;
    border-radius: 1.07rem;
}

div.mypage_inner div.mypage_processArea div.process_scroller::-webkit-scrollbar-track {
    background-color: #efefef;
    border-radius: 1.07rem;
}

div.mypage_inner div.mypage_processArea div.process_scroller ul.process_list.col6 li.locked p.mark {
    width: 5rem;
    height: 5rem;
    background: url(../images/common/icon_lock.png) no-repeat;
    opacity: 0.5;
    position: absolute;
    cursor: pointer;
}

ul.process_list.col6 li div.process_wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

ul.process_list.col6 li div.process_bar {
    display: block;
    width: 100%;
    height: 1.07rem;
    position: relative;
    background: #dde8ff;
    border-radius: 7.14rem;
    margin-top: 0.57rem;
}

ul.process_list.col6 li div.process_bar div.bar_wrapper {
    width: 100%;
    position: relative;
}

ul.process_list.col6 li div.process_bar div.bar_wrapper p.bar_doing {
    background: linear-gradient(90deg, #f4d1a7 1.81%, #ffab09 100%), #769efc;
    border-radius: 7.14rem;
    height: 1.07rem;
    position: absolute;
    width: 60%;
    left: 0;
    top: 0;
    margin-top: 0;
}

ul.process_list.col6 li div.process_bar span.per {
    background: #ff8415;
    font-size: 1rem;
    color: #fff;
    padding: 0.3rem 0.5rem;
    border-radius: 7.14rem;
    position: absolute;
    bottom: -2.2rem;
    right: -0.86rem;
}

ul.process_list.col6 li div.process_bar span.per::after {
    content: "";
    width: 0;
    height: 0;
    border-left: 0.57rem solid transparent;
    border-right: 0.57rem solid transparent;
    border-bottom: 0.64rem solid #ff8415;
    position: absolute;
    top: -0.29rem;
    right: 0.5rem;
}

ul.process_list.col6 li.doing div.process_wrapper {
    width: 100%;
}

ul.process_list.col6 li.doing div.process_bar div.bar_wrapper p.bar_doing {
    margin-top: 0;
}

ul.process_list.col6 li.locked div.process_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

ul.process_list.col6 li.locked div.process_bar {
    display: none;
}

div.myclass_event_wrapper h1.title {
    margin-top: 1.5rem;
    padding-top: 1.5rem;
    border-top: chocolate;
    font-size: 21px !important;
    margin-bottom: 1rem !important;
}

div.myclass_event_wrapper div.gift_img {
    display: inline-block;
    vertical-align: middle;
    margin-right: 3rem;
}

div.myclass_event_wrapper div.gift_img img {
    width: 180px;
}

div.myclass_event_wrapper ul.event_user_list {
    display: inline-block;
    vertical-align: middle;
    width: 470px;
}

.event_slider .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: #fff;
    padding: 1rem;
    border: 3px solid #e8edf8;
    border-radius: 10px;
    color: #4f74c5;
    height: auto;
}

.event_slider .swiper-slide h1 {
    font-size: 1rem;
    color: #4f74c5;
    vertical-align: middle;
    margin-top: 0;
    width: 15%;
}

.event_slider .swiper-slide h2 {
    font-size: 1rem;
    padding: 0 0.8rem;
    color: #4f74c5;
    font-family: noto_R;
    vertical-align: middle;
    margin-top: 0;
}

.event_slider .swiper-slide h3 {
    font-size: 1rem;
    color: #4f74c5;
    font-family: noto_L;
    vertical-align: middle;
}

.event_slider {
    display: inline-block;
    vertical-align: top;
}

div.swiper-container.m_class_slider {
    display: none;
}

.m_page_move {
    display: none;
}

.wrap {
    padding: 0 40px;
}

@media (max-width: 1700px) {
    div.page_content {
        height: 100%;
    }
    div.mypage_inner {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding-top: 3rem;
        flex-direction: column;
        align-items: center;
        padding-top: 0;
    }
    div.mypage_inner div.mypage_infoArea {
        display: flex;
        margin-right: 0;
        padding: 0 3rem;
        text-align: center;
        align-items: center;
        justify-content: center;
    }
    div.mypage_inner div.mypage_infoArea h1.title {
        font-size: 1.75rem;
        color: #333;
        margin-bottom: 1.5rem;
        display: block;
        margin-top: 0;
        padding-top: 0;
        text-align: left;
        display: none;
    }
    div.mypage_inner div.mypage_infoArea div.row {
        display: flex;
    }
    div.mypage_inner div.mypage_infoArea div.row div.bookImg {
        width: 12rem;
        height: 17rem;
        display: inline-block;
        /* background: url(../images/common/book_sample.png) no-repeat; */
        background-position: center;
        background-size: cover;
        border-radius: 0.36rem;
        box-shadow: 0rem 0.29rem 0.29rem rgba(0, 0, 0, 0.25);
        vertical-align: top;
        margin-right: 2.5rem;
    }
    div.mypage_inner div.mypage_infoArea div.row div {
        display: inline-block;
        vertical-align: top;
    }
    div.mypage_inner div.mypage_infoArea div.row div div.row {
        display: block;
        font-size: 0;
    }
    div.mypage_inner div.mypage_infoArea div.row div div.row:nth-of-type(3) {
        margin-top: 1.43rem;
        text-align: left;
    }
    div.mypage_inner div.mypage_infoArea div.row div div.row h1 {
        font-size: 1.35rem;
        margin-bottom: 0.7rem;
        text-align: left;
    }
    div.mypage_inner div.mypage_infoArea div.row div div.row:nth-of-type(2) {
        margin-top: 1rem;
    }
    .color_orange {
        color: #ff7a00;
    }
    div.mypage_inner div.mypage_infoArea div.row div div.row h2 {
        font-size: 1.25rem;
        color: #4f74c5;
        width: 20rem;
        height: auto;
        background: #fff;
        border: 0.21rem solid #e8edf8;
        border-radius: 10px;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 1.2rem;
        font-family: "noto_M";
        min-height: 4rem;
    }
    div.mypage_inner div.mypage_infoArea div.row div div.row div.inner_row {
        font-size: 0;
        font-size: 0;
    }
    div.mypage_inner div.mypage_infoArea div.row div div.row div.inner_row p {
        font-size: 1.1rem;
        color: #4f74c5;
        width: 9.43rem;
        height: 4rem;
        background: #fff;
        border: 0.21rem solid #e8edf8;
        border-radius: 10px;
        text-align: center;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
    }
    div.mypage_inner div.mypage_infoArea div.row div div.row div.inner_row span {
        font-size: 1.35rem;
        vertical-align: middle;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: #4f74c5;
        padding: 0 0.5rem;
    }
    div.mypage_inner div.mypage_infoArea div.row div div.row h3 {
        display: inline-block;
        vertical-align: middle;
        font-size: 1rem;
        margin-left: 0.57rem;
        margin-top: 0.3rem;
    }
    div.mypage_inner div.mypage_infoArea div.row div div.row h3 span {
        color: #ff8415;
    }
    div.mypage_inner div.mypage_processArea {
        vertical-align: top;
        display: block;
        padding: 0 3rem;
        margin-top: 3rem;
    }
    div.mypage_inner div.mypage_processArea h1.title {
        font-size: 2rem;
        color: #333;
        margin-bottom: 1rem;
        display: none;
    }
    div.mypage_inner div.mypage_processArea div.process_scroller {
        overflow-y: auto;
        height: 37.14rem;
        padding-right: 1rem;
        padding-left: 1.14rem;
        overflow-x: hidden;
        display: none;
    }
    div.mypage_inner div.mypage_processArea div.process_scroller ul.process_list.col6 {
        font-size: 0;
        width: calc(7.29rem * 6 + 1.43rem * 5);
        padding-top: 1.43rem;
    }
    div.mypage_inner div.mypage_processArea div.process_scroller ul.process_list.col6 li {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 7.29rem;
        height: auto;
        margin-right: 1.43rem;
        margin-top: 2.86rem;
        flex-direction: column;
        vertical-align: top;
        cursor: pointer;
    }
    div.mypage_inner div.mypage_processArea div.process_scroller ul.process_list.col6 li:nth-of-type(1) {
        margin-top: 0;
    }
    div.mypage_inner div.mypage_processArea div.process_scroller ul.process_list.col6 li:nth-of-type(2) {
        margin-top: 0;
    }
    div.mypage_inner div.mypage_processArea div.process_scroller ul.process_list.col6 li:nth-of-type(3) {
        margin-top: 0;
    }
    div.mypage_inner div.mypage_processArea div.process_scroller ul.process_list.col6 li:nth-of-type(4) {
        margin-top: 0;
    }
    div.mypage_inner div.mypage_processArea div.process_scroller ul.process_list.col6 li:nth-of-type(5) {
        margin-top: 0;
    }
    div.mypage_inner div.mypage_processArea div.process_scroller ul.process_list.col6 li:nth-of-type(6) {
        margin-top: inherit;
    }
    div.mypage_inner div.mypage_processArea div.process_scroller ul.process_list.col6 li:nth-of-type(6n) {
        margin-right: inherit;
    }
    div.mypage_inner div.mypage_processArea div.process_scroller ul.process_list.col6 li.completed p.number {
        background-color: #ffffff;
        border: 0.21rem solid #fbc08b;
        box-sizing: border-box;
        border-radius: 100%;
        display: block;
        position: absolute;
        top: -1.14rem;
        left: -1.14rem;
        width: 2.86rem;
        height: 2.86rem;
        z-index: 6;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #878787;
        font-size: 1.57rem;
    }
    div.mypage_inner div.mypage_processArea div.process_scroller ul.process_list.col6 li.completed a {
        opacity: 0.5;
    }
    div.mypage_inner div.mypage_processArea div.process_scroller ul.process_list.col6 li.completed p.mark {
        width: 5rem;
        height: 5rem;
        background: url(../images/common/icon_complete.png) no-repeat;
        opacity: 0.5;
        position: absolute;
        cursor: pointer;
    }
    div.mypage_inner div.mypage_processArea div.process_scroller ul.process_list.col6 li.doing p.number {
        background-color: #ffffff;
        border: 0.21rem solid #ff8515;
        box-sizing: border-box;
        border-radius: 100%;
        position: absolute;
        top: -1.14rem;
        left: -1.14rem;
        width: 2.86rem;
        height: 2.86rem;
        z-index: 6;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #333;
        font-size: 1.57rem;
    }
    div.mypage_inner div.mypage_processArea div.process_scroller ul.process_list.col6 li.locked p.number {
        background-color: #ffffff;
        border: 0.21rem solid #fbc08b;
        box-sizing: border-box;
        border-radius: 100%;
        display: block;
        position: absolute;
        top: -1.14rem;
        left: -1.14rem;
        width: 2.86rem;
        height: 2.86rem;
        z-index: 6;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #878787;
        font-size: 1.57rem;
        margin-top: 0;
        padding-top: 4px;
        padding-right: 1px;
    }
    div.mypage_inner div.mypage_processArea div.process_scroller ul.process_list.col6 li.locked a {
        opacity: 0.5;
    }
    div.mypage_inner div.mypage_processArea div.process_scroller ul.process_list.col6 li a {
        font-size: 1.3rem;
        text-align: center;
        width: 100%;
        height: 7.29rem;
        display: flex;
        align-items: center;
        justify-content: center;
        word-break: keep-all;
        border: 0.21rem solid #ffcc9d;
        box-sizing: border-box;
        border-radius: 0.71rem;
        background: #ff8515;
        color: #fff;
    }
    div.mypage_inner div.mypage_processArea div.process_scroller::-webkit-scrollbar {
        width: 1.07rem;
        background-color: #efefef;
        border-radius: 1.07rem;
    }
    div.mypage_inner div.mypage_processArea div.process_scroller::-webkit-scrollbar-thumb {
        background-color: #ff7b01;
        border-radius: 1.07rem;
    }
    div.mypage_inner div.mypage_processArea div.process_scroller::-webkit-scrollbar-track {
        background-color: #efefef;
        border-radius: 1.07rem;
    }
    div.mypage_inner div.mypage_processArea div.process_scroller ul.process_list.col6 li.locked p.mark {
        width: 5rem;
        height: 5rem;
        background: url(../images/common/icon_lock.png) no-repeat;
        opacity: 0.5;
        position: absolute;
        cursor: pointer;
    }
    ul.process_list.col6 li div.process_wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }
    ul.process_list.col6 li div.process_bar {
        display: block;
        width: 100%;
        height: 1.07rem;
        position: relative;
        background: #dde8ff;
        border-radius: 7.14rem;
        margin-top: 0.57rem;
    }
    ul.process_list.col6 li div.process_bar div.bar_wrapper {
        width: 100%;
        position: relative;
    }
    ul.process_list.col6 li div.process_bar div.bar_wrapper p.bar_doing {
        background: linear-gradient(90deg, #f4d1a7 1.81%, #ffab09 100%), #769efc;
        border-radius: 7.14rem;
        height: 1.07rem;
        position: absolute;
        width: 60%;
        left: 0;
        top: 0;
        margin-top: 0;
    }
    ul.process_list.col6 li div.process_bar span.per {
        background: #ff8415;
        font-size: 1rem;
        color: #fff;
        padding: 0.3rem 0.5rem;
        border-radius: 7.14rem;
        position: absolute;
        bottom: -2.2rem;
        right: -0.86rem;
    }
    ul.process_list.col6 li div.process_bar span.per::after {
        content: "";
        width: 0;
        height: 0;
        border-left: 0.57rem solid transparent;
        border-right: 0.57rem solid transparent;
        border-bottom: 0.64rem solid #ff8415;
        position: absolute;
        top: -0.29rem;
        right: 0.5rem;
    }
    ul.process_list.col6 li.doing div.process_bar div.bar_wrapper p.bar_doing {
        margin-top: 0;
    }
    ul.process_list.col6 li.locked div.process_wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }
    ul.process_list.col6 li.locked div.process_bar {
        display: none;
    }
    div.myclass_event_wrapper {
        display: inline-block;
        vertical-align: top;
        padding-left: 3rem;
    }
    div.myclass_event_wrapper h1.title {
        margin-top: 1.5rem;
        padding-top: 1.5rem;
        border-top: chocolate;
        font-size: 18px !important;
        margin-bottom: 1rem !important;
        margin-left: 1.25rem;
    }
    div.myclass_event_wrapper div.gift_img {
        display: inline-block;
        vertical-align: middle;
        margin-right: 1rem;
    }
    div.myclass_event_wrapper div.gift_img img {
        width: 150px;
        display: none;
    }
    div.myclass_event_wrapper ul.event_user_list {
        display: inline-block;
        vertical-align: middle;
        width: 470px;
    }
    .event_slider .swiper-slide {
        display: flex;
        align-items: center;
        justify-content: space-around;
        background: #fff;
        padding: 1rem;
        border: 3px solid #e8edf8;
        border-radius: 15px;
        color: #4f74c5;
        height: auto;
    }
    .event_slider .swiper-slide h1 {
        font-size: 1rem;
        color: #4f74c5;
        vertical-align: middle;
        margin-top: 0;
        width: 15%;
        text-align: left;
    }
    .event_slider .swiper-slide h2 {
        font-size: 1rem;
        padding: 0 0.8rem;
        color: #4f74c5;
        font-family: noto_R;
        vertical-align: middle;
        margin-top: 0;
    }
    .event_slider .swiper-slide h3 {
        font-size: 1rem;
        color: #4f74c5;
        font-family: noto_L;
        vertical-align: middle;
    }
    .event_slider {
        display: inline-block;
        vertical-align: top;
        width: 340px !important;
    }
    div.swiper-container.m_class_slider {
        display: block;
        height: auto;
    }
    div.swiper-container.m_class_slider div.swiper-wrapper {
        position: relative;
        height: auto;
    }
    div.swiper-container.m_class_slider div.swiper-wrapper div.swiper-slide {
        height: auto;
    }
    div.swiper-container.m_class_slider div.swiper-wrapper div.swiper-slide ul li.completed {
        opacity: 0.5;
    }
    div.swiper-container.m_class_slider div.swiper-wrapper div.swiper-slide ul {
        padding: 2rem 0;
        text-align: center;
        width: 100%;
        padding-bottom: 5rem;
    }
    div.swiper-container.m_class_slider div.swiper-wrapper div.swiper-slide ul li {
        display: inline-block;
        align-items: center;
        justify-content: center;
        width: 7.29rem;
        height: auto;
        flex-direction: column;
        vertical-align: top;
        cursor: pointer;
        height: 7.29rem;
        position: relative;
        margin-right: 3.3rem;
    }
    div.swiper-container.m_class_slider div.swiper-wrapper div.swiper-slide ul li.completed div.process_wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }
    div.swiper-container.m_class_slider div.swiper-wrapper div.swiper-slide ul li div.process_wrapper p.number {
        background-color: #ffffff;
        border: 0.21rem solid #fbc08b;
        box-sizing: border-box;
        border-radius: 100%;
        display: block;
        position: absolute;
        top: -1.14rem;
        left: -1.14rem;
        width: 2.86rem;
        height: 2.86rem;
        z-index: 6;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #878787;
        font-size: 1.3rem;
        margin-top: 0;
        padding-top: 4px;
        padding-right: 1px;
    }
    div.swiper-container.m_class_slider div.swiper-wrapper div.swiper-slide ul li.completed div.process_wrapper p.mark {
        width: 3.5rem;
        height: 3.5rem;
        background: url(../images/common/icon_complete.png) no-repeat;
        opacity: 0.5;
        position: absolute;
        cursor: pointer;
        background-size: cover;
        background-position: center;
    }
    div.swiper-container.m_class_slider div.swiper-wrapper div.swiper-slide ul li div.process_wrapper a {
        font-size: 1.3rem;
        text-align: center;
        width: 100%;
        height: 7.29rem;
        display: flex;
        align-items: center;
        justify-content: center;
        word-break: keep-all;
        border: 0.21rem solid #ffcc9d;
        box-sizing: border-box;
        border-radius: 0.71rem;
        background: #ff8515;
        color: #fff;
    }
    div.swiper-container.m_class_slider div.swiper-wrapper div.swiper-slide ul li.locked {
        opacity: 0.5;
    }
    div.swiper-container.m_class_slider div.swiper-wrapper div.swiper-slide ul li:last-of-type {
        margin-right: 0;
    }
    div.swiper-container.m_class_slider div.swiper-wrapper div.swiper-slide ul li.locked div.process_wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    div.swiper-container.m_class_slider div.swiper-wrapper div.swiper-slide ul li.locked div.process_wrapper p.mark {
        width: 3.5rem;
        height: 3.5rem;
        background: url(../images/common/icon_lock.png) no-repeat;
        opacity: 0.5;
        position: absolute;
        cursor: pointer;
        background-size: cover;
        background-position: center;
    }
    div.swiper-container.m_class_slider div.swiper-wrapper div.swiper-slide ul li div.process_bar {
        display: block;
        width: 100%;
        height: 1.07rem;
        background: #dde8ff;
        border-radius: 7.14rem;
        margin-top: 0.57rem;
    }
    div.swiper-container.m_class_slider div.swiper-wrapper div.swiper-slide ul li div.process_bar div.bar_wrapper {
        width: 100%;
        position: relative;
        background: center;
    }
    li.locked div.process_bar div.bar_wrapper {
        display: none;
    }
    div.swiper-container.m_class_slider div.swiper-wrapper div.swiper-slide ul li div.process_bar div.bar_wrapper p.bar_doing {
        background: linear-gradient(90deg, #f4d1a7 1.81%, #ffab09 100%), #769efc;
        border-radius: 7.14rem;
        height: 1.07rem;
        position: absolute;
        width: 60%;
        left: 0;
        top: 0;
        margin-top: 0;
    }
    div.swiper-container.m_class_slider div.swiper-wrapper div.swiper-slide ul li div.process_bar div.bar_wrapper p.bar_doing span.per {
        background: #ff8415;
        font-size: 1rem;
        color: #fff;
        padding: 0.3rem 0.5rem;
        border-radius: 7.14rem;
        position: absolute;
        bottom: -2.2rem;
        right: -0.86rem;
        border: 1px solid;
        z-index: 9999;
    }
    div.swiper-container.m_class_slider div.swiper-wrapper div.swiper-slide ul li.locked div.process_bar {
        display: none;
    }
    .m_myClass_wrapper {
        position: relative;
        height: auto;
        margin: 0 auto;
        width: 900px;
    }
    .m_myClass_wrapper_btns {
        width: 107%;
        position: absolute;
        display: flex;
        align-items: center;
        height: 0;
        justify-content: space-between;
        z-index: 6;
        top: 50%;
        left: -3.5%;
    }
    .m_myClass_wrapper_btns p {
        width: 70px;
        height: 70px;
        background-position: center !important;
        background-size: cover !important;
    }
    .m_myClass_wrapper_btns p:nth-of-type(1) {
        background: url(../images/mobile/m_slide_btn_left.png) no-repeat;
    }
    .m_myClass_wrapper_btns p:nth-of-type(2) {
        background: url(../images/mobile/m_slide_btn_right.png) no-repeat;
    }
}

@media (max-width: 1280px) {
    .m_myClass_wrapper {
        width: 800px;
    }
}

@media (max-width: 1100px) {
    .wrap {
        padding: 0;
    }
}

@media (max-width: 1023px) {
    div.wrap.open div.m_menu_wrapper {
        display: none;
    }
    .m_page_move {
        display: block;
        position: fixed;
        height: 70px;
        width: 70px;
        top: 50%;
        margin-top: -40px;
        right: 1rem;
        background: url(../images/mobile/m_slide_btn_right.png) no-repeat;
        background-size: cover;
        background-position: center;
        z-index: 7;
    }
    div.wrap.open .m_page_move {
        right: unset;
        left: 1rem;
        background: url(../images/mobile/m_slide_btn_left.png) no-repeat;
        background-size: cover;
        background-position: center;
    }
    div.mypage_inner {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
        flex-direction: column;
        align-items: center;
        padding-top: 0;
        padding: 0;
    }
    div.mypage_inner div.mypage_infoArea {
        display: block;
        margin-right: 0;
        padding: 0 2rem;
        text-align: center;
    }
    div.wrap.open div.mypage_inner div.mypage_infoArea {
        display: none;
    }
    div.mypage_inner div.mypage_infoArea h1.title {
        font-size: 1.75rem;
        color: #333;
        margin-bottom: 1.5rem;
        display: block;
        margin-top: 0;
        padding-top: 0;
        text-align: left;
        display: none;
    }
    div.mypage_inner div.mypage_infoArea div.row {
        display: inline-block;
        vertical-align: top;
    }
    div.mypage_inner div.mypage_infoArea div.row div.bookImg {
        width: 12rem;
        height: 16rem;
        display: inline-block;
        /* background: url(../images/common/book_sample.png) no-repeat; */
        background-position: center;
        background-size: cover;
        border-radius: 0.36rem;
        box-shadow: 0rem 0.29rem 0.29rem rgba(0, 0, 0, 0.25);
        vertical-align: top;
        margin-right: 2rem;
    }
    div.wrap.open div.mypage_inner div.mypage_infoArea div.row div.bookImg {
        width: 10rem;
        height: 14rem;
        margin-right: 1.5rem;
    }
    div.mypage_inner div.mypage_infoArea div.row div {
        display: inline-block;
        vertical-align: top;
    }
    div.mypage_inner div.mypage_infoArea div.row div div.row {
        display: block;
        font-size: 0;
    }
    div.mypage_inner div.mypage_infoArea div.row div div.row:nth-of-type(3) {
        margin-top: 1.43rem;
        text-align: left;
    }
    div.mypage_inner div.mypage_infoArea div.row div div.row h1 {
        font-size: 1.35rem;
        margin-bottom: 0.7rem;
        text-align: left;
    }
    div.wrap.open div.mypage_inner div.mypage_infoArea div.row div div.row h1 {
        font-size: 1.1rem;
    }
    div.mypage_inner div.mypage_infoArea div.row div div.row:nth-of-type(2) {
        margin-top: 1rem;
    }
    .color_orange {
        color: #ff7a00;
    }
    div.mypage_inner div.mypage_infoArea div.row div div.row h2 {
        font-size: 1.25rem;
        color: #4f74c5;
        width: 22rem;
        height: auto;
        background: #fff;
        border: 0.21rem solid #e8edf8;
        border-radius: 10px;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 1.2rem;
        font-family: "noto_M";
        min-height: 3.8rem;
    }
    div.wrap.open div.mypage_inner div.mypage_infoArea div.row div div.row h2 {
        font-size: 1rem;
        width: 15rem;
        min-height: 4rem;
    }
    div.mypage_inner div.mypage_infoArea div.row div div.row div.inner_row {
        font-size: 0;
        font-size: 0;
    }
    div.mypage_inner div.mypage_infoArea div.row div div.row div.inner_row p {
        font-size: 1.25rem;
        color: #4f74c5;
        width: 10.43rem;
        height: 3.8rem;
        background: #fff;
        border: 0.21rem solid #e8edf8;
        border-radius: 1rem;
        text-align: center;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
    }
    div.wrap.open div.mypage_inner div.mypage_infoArea div.row div div.row div.inner_row p {
        font-size: 1.1rem;
        width: 7.43rem;
        height: 3.5rem;
    }
    div.mypage_inner div.mypage_infoArea div.row div div.row div.inner_row span {
        font-size: 1.35rem;
        vertical-align: middle;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: #4f74c5;
        padding: 0 0.5rem;
    }
    div.mypage_inner div.mypage_infoArea div.row div div.row p.icon_notice {
        display: inline-flex;
        font-size: 1rem;
        vertical-align: middle;
        color: #fff;
        border-radius: 0.5rem;
        background: #ff8415;
        width: 1.75rem;
        height: 1.75rem;
        align-items: center;
        justify-content: center;
        padding-top: 0.29rem;
    }
    div.mypage_inner div.mypage_infoArea div.row div div.row h3 {
        display: inline-block;
        vertical-align: middle;
        font-size: 1.25rem;
        margin-left: 0.57rem;
        margin-top: 0.3rem;
    }
    div.wrap.open div.mypage_inner div.mypage_infoArea div.row div div.row h3 {
        font-size: 1rem;
    }
    div.mypage_inner div.mypage_infoArea div.row div div.row h3 span {
        color: #ff8415;
    }
    div.mypage_inner div.mypage_processArea {
        vertical-align: top;
        display: block;
        padding: 0 3rem;
        margin-top: 3rem;
    }
    div.mypage_inner div.mypage_processArea h1.title {
        font-size: 2rem;
        color: #333;
        margin-bottom: 1rem;
        display: none;
    }
    div.mypage_inner div.mypage_processArea div.process_scroller {
        overflow-y: auto;
        height: 37.14rem;
        padding-right: 1rem;
        padding-left: 1.14rem;
        overflow-x: hidden;
        display: none;
    }
    div.wrap.open div.mypage_inner div.mypage_processArea div.process_scroller {
        height: 80vh;
        display: block;
    }
    div.mypage_inner div.mypage_processArea div.process_scroller ul.process_list.col6 {
        font-size: 0;
        width: calc(7.29rem * 6 + 1.43rem * 5);
        padding-top: 1.43rem;
    }
    div.wrap.open div.mypage_inner div.mypage_processArea div.process_scroller ul.process_list.col6 {
        width: 100%;
        padding-top: 0;
    }
    div.mypage_inner div.mypage_processArea div.process_scroller ul.process_list.col6 li {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 7.29rem;
        height: auto;
        margin-right: 1.43rem;
        margin-top: 2.86rem;
        flex-direction: column;
        vertical-align: top;
        cursor: pointer;
    }
    div.wrap.open div.mypage_inner div.mypage_processArea div.process_scroller ul.process_list.col6 li {
        margin-right: 1rem !important;
        margin-top: 3rem !important;
    }
    div.mypage_inner div.mypage_processArea div.process_scroller ul.process_list.col6 li:nth-of-type(1) {
        margin-top: 0;
    }
    div.mypage_inner div.mypage_processArea div.process_scroller ul.process_list.col6 li:nth-of-type(2) {
        margin-top: 0;
    }
    div.mypage_inner div.mypage_processArea div.process_scroller ul.process_list.col6 li:nth-of-type(3) {
        margin-top: 0;
    }
    div.mypage_inner div.mypage_processArea div.process_scroller ul.process_list.col6 li:nth-of-type(4) {
        margin-top: 0;
    }
    div.mypage_inner div.mypage_processArea div.process_scroller ul.process_list.col6 li:nth-of-type(5) {
        margin-top: 0;
    }
    div.mypage_inner div.mypage_processArea div.process_scroller ul.process_list.col6 li:nth-of-type(6) {
        margin-top: inherit;
    }
    div.mypage_inner div.mypage_processArea div.process_scroller ul.process_list.col6 li:nth-of-type(6n) {
        margin-right: inherit;
    }
    div.mypage_inner div.mypage_processArea div.process_scroller ul.process_list.col6 li.completed p.number {
        background-color: #ffffff;
        border: 0.21rem solid #fbc08b;
        box-sizing: border-box;
        border-radius: 100%;
        display: block;
        position: absolute;
        top: -1.14rem;
        left: -1.14rem;
        width: 2.86rem;
        height: 2.86rem;
        z-index: 6;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #878787;
        font-size: 1.57rem;
    }
    div.mypage_inner div.mypage_processArea div.process_scroller ul.process_list.col6 li.completed a {
        opacity: 0.5;
    }
    div.mypage_inner div.mypage_processArea div.process_scroller ul.process_list.col6 li.completed p.mark {
        width: 5rem;
        height: 5rem;
        background: url(../images/common/icon_complete.png) no-repeat;
        opacity: 0.5;
        position: absolute;
        cursor: pointer;
    }
    div.wrap.open div.mypage_inner div.mypage_processArea div.process_scroller ul.process_list.col6 li.completed p.mark {
        background-position: center !important;
        background-size: cover !important;
    }

    div.mypage_inner div.mypage_processArea div.process_scroller ul.process_list.col6 li.doing p.number {
        background-color: #ffffff;
        border: 0.21rem solid #ff8515;
        box-sizing: border-box;
        border-radius: 100%;
        position: absolute;
        top: -1.14rem;
        left: -1.14rem;
        width: 2.86rem;
        height: 2.86rem;
        z-index: 6;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #333;
        font-size: 1.57rem;
    }

    div.mypage_inner div.mypage_processArea div.process_scroller ul.process_list.col6 li.locked p.number {
        background-color: #ffffff;
        border: 0.21rem solid #fbc08b;
        box-sizing: border-box;
        border-radius: 100%;
        display: block;
        position: absolute;
        top: -1.14rem;
        left: -1.14rem;
        width: 2.86rem;
        height: 2.86rem;
        z-index: 6;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #878787;
        font-size: 1.57rem;
        margin-top: 0;
        padding-top: 4px;
        padding-right: 1px;
    }
    div.mypage_inner div.mypage_processArea div.process_scroller ul.process_list.col6 li.locked a {
        opacity: 0.5;
    }
    div.mypage_inner div.mypage_processArea div.process_scroller ul.process_list.col6 li a {
        font-size: 1.3rem;
        text-align: center;
        width: 100%;
        height: 7.29rem;
        display: flex;
        align-items: center;
        justify-content: center;
        word-break: keep-all;
        border: 0.21rem solid #ffcc9d;
        box-sizing: border-box;
        border-radius: 0.71rem;
        background: #ff8515;
        color: #fff;
    }
    div.mypage_inner div.mypage_processArea div.process_scroller::-webkit-scrollbar {
        width: 1.07rem;
        background-color: #efefef;
        border-radius: 1.07rem;
    }
    div.mypage_inner div.mypage_processArea div.process_scroller::-webkit-scrollbar-thumb {
        background-color: #ff7b01;
        border-radius: 1.07rem;
    }
    div.mypage_inner div.mypage_processArea div.process_scroller::-webkit-scrollbar-track {
        background-color: #efefef;
        border-radius: 1.07rem;
    }
    div.mypage_inner div.mypage_processArea div.process_scroller ul.process_list.col6 li.locked p.mark {
        width: 5rem;
        height: 5rem;
        background: url(../images/common/icon_lock.png) no-repeat;
        opacity: 0.5;
        position: absolute;
        cursor: pointer;
    }
    div.wrap.open div.mypage_inner div.mypage_processArea div.process_scroller ul.process_list.col6 li.locked p.mark {
        background-position: center !important;
        background-size: cover !important;
    }
    ul.process_list.col6 li div.process_wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }
    ul.process_list.col6 li div.process_bar {
        display: block;
        width: 100%;
        height: 1.07rem;
        position: relative;
        background: #dde8ff;
        border-radius: 7.14rem;
        margin-top: 0.57rem;
    }
    ul.process_list.col6 li div.process_bar div.bar_wrapper {
        width: 100%;
        position: relative;
    }
    ul.process_list.col6 li div.process_bar div.bar_wrapper p.bar_doing {
        background: linear-gradient(90deg, #f4d1a7 1.81%, #ffab09 100%), #769efc;
        border-radius: 7.14rem;
        height: 1.07rem;
        position: absolute;
        width: 60%;
        left: 0;
        top: 0;
        margin-top: 0;
    }
    ul.process_list.col6 li div.process_bar span.per {
        background: #ff8415;
        font-size: 1rem;
        color: #fff;
        padding: 0.3rem 0.5rem;
        border-radius: 7.14rem;
        position: absolute;
        bottom: -2.2rem;
        right: -0.86rem;
    }
    ul.process_list.col6 li div.process_bar span.per::after {
        content: "";
        width: 0;
        height: 0;
        border-left: 0.57rem solid transparent;
        border-right: 0.57rem solid transparent;
        border-bottom: 0.64rem solid #ff8415;
        position: absolute;
        top: -0.29rem;
        right: 0.5rem;
    }
    ul.process_list.col6 li.doing div.process_bar div.bar_wrapper p.bar_doing {
        margin-top: 0;
    }

    ul.process_list.col6 li.locked div.process_wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }

    ul.process_list.col6 li.locked div.process_bar {
        display: none;
    }

    div.myclass_event_wrapper {
        display: inline-block;
        vertical-align: top;
        padding-left: 1rem;
    }
    div.myclass_event_wrapper h1.title {
        margin-top: 1.5rem;
        padding-top: 1.5rem;
        border-top: chocolate;
        font-size: 18px !important;
        margin-bottom: 1rem !important;
        margin-left: 1.25rem;
    }
    div.myclass_event_wrapper div.gift_img {
        display: inline-block;
        vertical-align: middle;
        margin-right: 1rem;
    }
    div.myclass_event_wrapper div.gift_img img {
        width: 150px;
        display: none;
    }
    div.myclass_event_wrapper ul.event_user_list {
        display: inline-block;
        vertical-align: middle;
        width: 470px;
    }
    .event_slider .swiper-slide {
        display: flex;
        align-items: center;
        justify-content: space-around;
        background: #fff;
        padding: 1rem;
        border: 3px solid #e8edf8;
        border-radius: 15px;
        color: #4f74c5;
        height: auto;
    }
    .event_slider .swiper-slide h1 {
        font-size: 1rem;
        color: #4f74c5;
        vertical-align: middle;
        margin-top: 0;
        width: 15%;
        text-align: left;
    }
    .event_slider .swiper-slide h2 {
        font-size: 1rem;
        padding: 0 0.8rem;
        color: #4f74c5;
        font-family: noto_R;
        vertical-align: middle;
        margin-top: 0;
    }
    .event_slider .swiper-slide h3 {
        font-size: 1rem;
        color: #4f74c5;
        font-family: noto_L;
        vertical-align: middle;
        display: none;
    }
    .event_slider {
        display: inline-block;
        vertical-align: top;
        width: 250px !important;
    }
    div.swiper-container.m_class_slider {
        display: block;
        height: auto;
    }
    div.swiper-container.m_class_slider div.swiper-wrapper {
        position: relative;
        height: auto;
    }
    div.swiper-container.m_class_slider div.swiper-wrapper div.swiper-slide {
        height: auto;
    }

    div.swiper-container.m_class_slider div.swiper-wrapper div.swiper-slide ul li.completed {
        opacity: 0.5;
    }
    div.swiper-container.m_class_slider div.swiper-wrapper div.swiper-slide ul {
        padding: 2rem 0;
        text-align: center;
        width: 100%;
        padding-bottom: 5rem;
    }
    div.swiper-container.m_class_slider div.swiper-wrapper div.swiper-slide ul li {
        display: inline-block;
        align-items: center;
        justify-content: center;
        width: 7.29rem;
        height: auto;
        flex-direction: column;
        vertical-align: top;
        cursor: pointer;
        height: 7.29rem;
        position: relative;
        margin-right: 1.3rem;
    }

    div.swiper-container.m_class_slider div.swiper-wrapper div.swiper-slide ul li.completed div.process_wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }
    div.swiper-container.m_class_slider div.swiper-wrapper div.swiper-slide ul li div.process_wrapper p.number {
        background-color: #ffffff;
        border: 0.21rem solid #fbc08b;
        box-sizing: border-box;
        border-radius: 100%;
        display: block;
        position: absolute;
        top: -1.14rem;
        left: -1.14rem;
        width: 2.86rem;
        height: 2.86rem;
        z-index: 6;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #878787;
        font-size: 1.3rem;
        margin-top: 0;
        padding-top: 4px;
        padding-right: 1px;
    }

    div.swiper-container.m_class_slider div.swiper-wrapper div.swiper-slide ul li.completed div.process_wrapper p.mark {
        width: 3.5rem;
        height: 3.5rem;
        background: url(../images/common/icon_complete.png) no-repeat;
        opacity: 0.5;
        position: absolute;
        cursor: pointer;
        background-size: cover;
        background-position: center;
    }
    div.swiper-container.m_class_slider div.swiper-wrapper div.swiper-slide ul li div.process_wrapper a {
        font-size: 1.3rem;
        text-align: center;
        width: 100%;
        height: 7.29rem;
        display: flex;
        align-items: center;
        justify-content: center;
        word-break: keep-all;
        border: 0.21rem solid #ffcc9d;
        box-sizing: border-box;
        border-radius: 0.71rem;
        background: #ff8515;
        color: #fff;
    }
    div.swiper-container.m_class_slider div.swiper-wrapper div.swiper-slide ul li.locked {
        opacity: 0.5;
    }
    div.swiper-container.m_class_slider div.swiper-wrapper div.swiper-slide ul li:last-of-type {
        margin-right: 0;
    }
    div.swiper-container.m_class_slider div.swiper-wrapper div.swiper-slide ul li.locked div.process_wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    div.swiper-container.m_class_slider div.swiper-wrapper div.swiper-slide ul li.locked div.process_wrapper p.mark {
        width: 3.5rem;
        height: 3.5rem;
        background: url(../images/common/icon_lock.png) no-repeat;
        opacity: 0.5;
        position: absolute;
        cursor: pointer;
        background-size: cover;
        background-position: center;
    }
    div.swiper-container.m_class_slider div.swiper-wrapper div.swiper-slide ul li div.process_bar {
        display: block;
        width: 100%;
        height: 1.07rem;
        background: #dde8ff;
        border-radius: 7.14rem;
        margin-top: 0.57rem;
    }
    div.swiper-container.m_class_slider div.swiper-wrapper div.swiper-slide ul li div.process_bar div.bar_wrapper {
        width: 100%;
        position: relative;
        background: center;
    }
    li.locked div.process_bar div.bar_wrapper {
        display: none;
    }
    div.swiper-container.m_class_slider div.swiper-wrapper div.swiper-slide ul li div.process_bar div.bar_wrapper p.bar_doing {
        background: linear-gradient(90deg, #f4d1a7 1.81%, #ffab09 100%), #769efc;
        border-radius: 7.14rem;
        height: 1.07rem;
        position: absolute;
        width: 60%;
        left: 0;
        top: 0;
        margin-top: 0;
    }
    div.swiper-container.m_class_slider div.swiper-wrapper div.swiper-slide ul li div.process_bar div.bar_wrapper p.bar_doing span.per {
        background: #ff8415;
        font-size: 1rem;
        color: #fff;
        padding: 0.3rem 0.5rem;
        border-radius: 7.14rem;
        position: absolute;
        bottom: -2.2rem;
        right: -0.86rem;
        border: 1px solid;
        z-index: 9999;
    }
    div.swiper-container.m_class_slider div.swiper-wrapper div.swiper-slide ul li.locked div.process_bar {
        display: none;
    }
    .m_myClass_wrapper {
        position: relative;
        height: auto;
        width: 770px;
        margin: 0 auto;
    }
    div.wrap.open .m_myClass_wrapper {
        display: none;
    }
    .m_myClass_wrapper_btns {
        width: 100%;
        position: absolute;
        display: flex;
        align-items: center;
        height: 0;
        justify-content: space-between;
        z-index: 6;
        top: 50%;
    }
    .m_myClass_wrapper_btns p {
        width: 70px;
        height: 70px;
        background-position: center !important;
        background-size: cover !important;
    }
    .m_myClass_wrapper_btns p:nth-of-type(1) {
        background: url(../images/mobile/m_slide_btn_left.png) no-repeat;
    }
    .m_myClass_wrapper_btns p:nth-of-type(2) {
        background: url(../images/mobile/m_slide_btn_right.png) no-repeat;
    }
    div.mypage_inner div.mypage_processArea {
        vertical-align: top;
        display: block;
        padding: 0 3rem;
        margin-top: 3rem;
        display: none;
    }
    div.wrap.open div.mypage_inner div.mypage_processArea {
        margin-top: 0;
        display: block;
        padding-left: 5rem;
        padding-right: 1rem;
    }
}

@media (max-width: 930px) {
    .event_slider {
        height: 50px !important;
        width: 100% !important;
    }
    div.wrap.open .event_slider {
        height: 70px !important;
    }
    div.myclass_event_wrapper {
        width: 100%;
    }
    .event_slider .swiper-slide h1 {
        font-size: 1.15rem;
        color: #4f74c5;
        vertical-align: middle;
        margin-top: 0;
        width: 15%;
        text-align: left;
    }
    div.wrap.open .event_slider .swiper-slide h1 {
        font-size: 1.3rem;
    }
    .event_slider .swiper-slide h2 {
        font-size: 1.15rem;
        padding: 0 0.8rem;
        color: #4f74c5;
        vertical-align: middle;
        margin-top: 0;
    }
    div.wrap.open .event_slider .swiper-slide h2 {
        font-size: 1.3rem;
        font-family: noto_R;
    }
    div.mypage_inner div.mypage_infoArea div.row div div.row:nth-of-type(3) {
        margin-top: 0;
        text-align: left;
    }
    div.myclass_event_wrapper {
        margin-top: 1rem;
    }
    div.mypage_inner div.mypage_infoArea div.row {
        display: inline-flex;
        vertical-align: top;
        align-items: flex-start;
        width: 100%;
        padding: 0 1rem;
        margin-bottom: 1rem;
    }
    div.wrap.open div.mypage_inner div.mypage_infoArea div.row {
        align-items: center;
        justify-content: space-around;
        padding: 0 2rem;
        margin-bottom: unset;
    }
}

@media (max-width: 690px) {
    div.mypage_inner div.mypage_infoArea div.row div div.row div.inner_row p {
        width: 9.43rem;
    }
    div.wrap.open div.mypage_inner div.mypage_infoArea div.row div div.row div.inner_row p {
        width: unset;
    }
    div.myclass_event_wrapper {
        margin-top: 0 !important;
    }
    div.wrap.open div.myclass_event_wrapper {
        margin-top: unset !important;
    }
}

@media screen and (min-width: 900px) and (max-width: 1200px) {
    div.myclass_event_wrapper h1.title {
        display: block !important;
        font-size: 1.35rem !important;
    }
    div.myclass_event_wrapper div.gift_img img {
        display: block;
    }
    .event_slider {
        width: 290px !important;
    }
}
@media screen and (min-width: 900px) and (max-width: 1024) {
    div.myclass_event_wrapper div.gift_img img {
        width: 130px !important;
    }
    .event_slider {
        width: 260px !important;
        height: 160px !important;
    }
    div.m_menu_wrapper {
        display: none;
    }
    div.page_header {
        display: flex;
    }
    div.page_content {
        height: 95.6%;
    }
    div.mypage_inner {
        padding-bottom: 0;
    }
    .m_myClass_wrapper_btns {
        top: 41%;
    }
}
@media screen and (min-width: 900px) and (max-width: 962px) {
    div.page_content {
        height: 79.6%;
    }
    .m_page_move,
    .m_menu_wrapper {
        display: none;
    }
    div.mypage_inner div.mypage_processArea {
        display: block;
    }
    .m_myClass_wrapper {
        width: 800px;
    }
    .m_myClass_wrapper_btns {
        left: 0;
    }
}

/* 모바일 */
@media screen and (max-width: 800px) {
    div.mypage_inner div.myclass_event_wrapper h1.title {
        display: block;
        font-size: 1.5rem !important;
    }
    div.myclass_event_wrapper {
        margin-top: 0.5rem;
    }
}

@media screen and (min-width: 800px) and (max-width: 899px) {
    div.mypage_inner div.myclass_event_wrapper h1.title {
        display: block;
        position: absolute;
        top: 63px;
        right: 72px;
        margin-left: 0;
    }
    div.myclass_event_wrapper div.gift_img {
        position: absolute;
        top: 110px;
        right: 80px;
    }
    div.myclass_event_wrapper div.gift_img img {
        display: block;
        width: 200px;
    }
}
